export default {
  COMPANY_NAME: 'The Flowery',
  STORE_URL: 'https://api.theflowery.co',
  PLATFORM_URL: 'https://app.getsalve.co',
  IMAGE_KIT_URL: 'https://ik.imagekit.io/salve/',
  SALVE_TOKEN: '3931|H9jGseC8IWucUdlNmcCRParxGrdhFksKpP08E8hp',
  APP_ENV: 'production',
  GOOGLE_TOKEN: 'UA-180965403-1',
  GOOGLE_APP_NAME: 'The Flowery',
  ZENDESK_TOKEN: '8a2271ef-dfac-4682-bd5f-236439ce108f',
  SENTRY_DSN: 'https://6c9274fbe33b4ad4039c529a7fbb7a42@o1103062.ingest.sentry.io/4505885334044672',
  SENTRY_AUTH_TOKEN:
    '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',
  STORE_ROUTES: {
    // Fetches data for the navbar and footer
    NAVIGATION_DATA: '/wp-json/acf/v3/options/options'
  },
  STORE_FEATURES: {
    IN_STORE_PICKUP: true
  },
  STORE_CONFIG: {
    HOMESTEAD_ID: 1,
    EMPLOYEE_ONLY_PICKUP_LOCATIONS: [1],
    DEFAULT_LOCATION_ID: 1
  }
}

